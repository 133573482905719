import { DirectiveBinding } from 'vue'

interface OnClickOutsideElement extends HTMLElement {
  clickOutsideEvent?: (event: Event) => void
}

const onClickOutsideDirective = {
  bind(el: OnClickOutsideElement, binding: DirectiveBinding<(event: Event) => void>) {
    el.clickOutsideEvent = function (event: Event) {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event)
      }
    }

    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el: OnClickOutsideElement) {
    if (el.clickOutsideEvent) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    }
  },
}

export default onClickOutsideDirective
